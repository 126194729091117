var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{staticClass:"hp-profile-menu py-24 px-0",staticStyle:{"flex":"0 0 240px"}},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"hp-profile-menu-header mt-16 mt-lg-0 text-center"},[_c('div',{staticClass:"hp-menu-header-btn mb-12 text-right"}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"d-inline-block position-relative"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center rounded-circle",staticStyle:{"width":"80px","height":"80px"}},[_c('img',{attrs:{"src":require('@/assets/img/building/building.png')}})])])]),_c('h3',{staticClass:"mt-24 mb-4"},[_vm._v(_vm._s(_vm.data.name))]),_c('div',{staticClass:"hp-p1-body"},[_c('h2',[_vm._v("Pymes")]),_c('div',[_vm._v("Aquí podrás ver todo lo relacionado con los pymes")])]),_c('br')])]),_c('div',{staticClass:"hp-profile-menu-body w-100 text-left mt-48 mt-lg-0"},[_c('ul',[_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.route_name == 'admin-publicidad' || _vm.route_name == 'admin-publicidad-category'  ? ' active' : ''
          }`,attrs:{"to":_vm.category}},[_c('i',{staticClass:"iconly-Broken-Home mr-8"}),_c('span',[_vm._v("Categorias")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.route_name == 'admin-publicidad-company' ? ' active' : ''
          }`,attrs:{"to":_vm.company}},[_c('i',{staticClass:"iconly-Broken-Home mr-8"}),_c('span',[_vm._v("Empresas")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.route_name == 'admin-publicidad-ads' ? ' active' : ''
          }`,attrs:{"to":_vm.ads}},[_c('i',{staticClass:"iconly-Broken-Home mr-8"}),_c('span',[_vm._v("Anuncios")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1)])]),_c('div',{staticClass:"hp-profile-menu-footer"},[_c('img',{attrs:{"src":require('@/assets/img/pages/profile/menu-img.svg'),"alt":"Profile Image"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }