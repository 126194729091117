<template>
  <b-col class="hp-profile-menu py-24 px-0" style="flex: 0 0 240px">
    <div class="w-100">
      <div class="hp-profile-menu-header mt-16 mt-lg-0 text-center">
        <div class="hp-menu-header-btn mb-12 text-right">
          <!--
          <b-dropdown variant="link" no-caret toggle-class="p-0">
            <template #button-content>
              <div class="d-inline-block">
                <b-button variant="text" class="btn btn-icon-only">
                  <i
                    class="ri-more-2-line text-black-100 hp-text-color-dark-30 lh-1"
                    style="font-size: 24px"
                  ></i>
                </b-button>
              </div>
            </template>
            <b-dropdown-item href="#">Change Avatar</b-dropdown-item>
          </b-dropdown>
          -->
        </div>

        <div class="d-flex justify-content-center">
          <div class="d-inline-block position-relative">
            <div
              class=" d-flex align-items-center justify-content-center rounded-circle"
              style="width: 80px; height: 80px"
            >
              <img :src="require('@/assets/img/building/building.png')" />
            </div>

            <!-- <b-badge
              pill
              class="position-absolute bg-primary text-white border-0"
            >
              12
            </b-badge> -->
          </div>
        </div>

        <h3 class="mt-24 mb-4">{{ data.name }}</h3>
        <div class="hp-p1-body">
          <h2>Pymes</h2>
          <div>Aquí podrás ver todo lo relacionado con los pymes</div>
        </div>
        <br/>
      </div>
    </div>

    <div class="hp-profile-menu-body w-100 text-left mt-48 mt-lg-0">
      <ul>

        <li class="mt-4 mb-16">
          <b-link
            :to=category
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              route_name == 'admin-publicidad' || route_name == 'admin-publicidad-category'  ? ' active' : ''
            }`"
          >
          <i class="iconly-Broken-Home mr-8"></i>
            <span>Categorias</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            :to=company
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              route_name == 'admin-publicidad-company' ? ' active' : ''
            }`"
          >
          <i class="iconly-Broken-Home mr-8"></i>
            <span>Empresas</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            :to=ads
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              route_name == 'admin-publicidad-ads' ? ' active' : ''
            }`"
          >
          <i class="iconly-Broken-Home mr-8"></i>
            <span>Anuncios</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>
 
      </ul>
    </div>

    <div class="hp-profile-menu-footer">
      <img
        :src="require('@/assets/img/pages/profile/menu-img.svg')"
        alt="Profile Image"
      />
    </div>
  </b-col>
</template>

<script>
import {
  BCol,
  BBadge,
  BButton,
  BLink,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
 

export default {
  data() {
      return {
        data : this.building,
        departamentData: this.departaments,
        path : "",
        params : "",
        route_name : "",
        category: "",
        company : "",
        ads : "",

      };
  },
  props: {
    building:{
      type: Object,
    },
    departaments:{
      type: Object,
    },
  },
  components: {
    BCol,
    BBadge,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  watch: {
    $route() {
      this.getRoute();
    },
  },
  methods: {
    getRoute() {
      this.path = this.$route.path;
      this.params = this.$route.params;
      this.route_name = this.$route.name;
      
      this.category = '/admin/modulos/pymes/categorias';
      this.company = '/admin/modulos/pymes/empresas';
      this.ads = '/admin/modulos/pymes/anuncios';
      
    }
 
  },
  beforeMount() {
 
  },
  created() {
    this.getRoute();
  },
};
</script>
