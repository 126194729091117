//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class Locations {
 
  list(){
    return http.get( api.location.get.endpoint+'?region_name=chile' );
  }

  listbyId(id){
    return http.get( api.location.get.endpoint+'?region_id='+id);
  }

  listCity(){
    return http.get( api.location.get.endpoint );
  }

}

export default new Locations();