//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class Regions {
 
  list(){
    return http.get( api.regions.get.endpoint );
  }

}

export default new Regions();