<template>
  <b-row>
    <b-col cols="12">
      <h2>Categorias de Pymes</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-16">
      <datatable :items="items" :fields="fields">
        <template slot="filters">
          <b-col cols="3" class="my-3">
            <b-button
              variant="primary"
              class="btn-ghost"
              v-b-modal.modal-add-sales-admin
            >
              Añadir nueva categoria
            </b-button>
          </b-col>
        </template>

        <template slot-scope="{ item }" slot="actions">
          <b-row class="actions-icons action_ads_category">
            <span
              title="ver"
              @click="showUser(item.uuid)"
              :id="'_show_button_' + item.uuid"
            >
              <i class="iconly-Light-Show"></i>
            </span>
            <span
              title="editar"
              @click="editUser(item.uuid)"
              :id="'_show_editar' + item.uuid"
            >
              <i class="iconly-Light-Edit mr-10"></i>
            </span>
            <span
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
              :id="'_show_eliminar' + item.uuid"
            >
              <i class="iconly-Light-Delete mr-10"></i>
            </span>
          </b-row>

          <b-tooltip :target="'_show_button_' + item.uuid" triggers="hover">
            Ver
          </b-tooltip>

          <b-tooltip :target="'_show_editar' + item.uuid" triggers="hover">
            Editar
          </b-tooltip>

          <b-tooltip :target="'_show_eliminar' + item.uuid" triggers="hover">
            Eliminar
          </b-tooltip>
        </template>
      </datatable>
    </div>

    <!-- create sale -->
    <b-modal
      size="xl"
      id="modal-add-sales-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-sales-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nueva categoria</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre de la categoría">
              <b-form-input
                v-model="createSales.name"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-sales-admin')"
            >
              Cerrar
            </b-button>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="create_sale">
              Añadir
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- edit sale -->
    <b-modal
      size="xl"
      id="modal-edit-sales-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-edit-sales-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Editar categoria</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre de la categoría">
              <b-form-input
                v-model="createSales.name"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="submitFileEdit">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-sales-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Confirmar eliminación</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar este pymes?
          </p>
        </b-col>

        <b-col cols="6" class="pr-2">
          <b-button variant="danger" block @click="confirmDelete">
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col cols="6" class="pl-2">
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- View sale detail -->
    <b-modal
      id="modal-show-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Ver detalle de categoria</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre de la categoría">
              <b-form-input
                v-model="createSales.name"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-show-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
} from "bootstrap-vue";

import datatable from "@/layouts/components/datatable/Datatable.vue";
import sweet from "@/logic/functions/sweetAlert";
import Publicity from "@/logic/functions/publicity";
import { DateTime } from "luxon";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
    datatable,
  },
  data() {
    return {
      fields: [
        {
          key: "created_at",
          label: "Nombre",
          formatter: (value, key, item) => {
            return item.name;
          },
        },
        // { key: "description", label: "Descripción" },
        // { key: "date_start", label: "Inicio",
        // formatter: (value, key, item) => {
        //   return DateTime
        //       .fromISO(item.date_start)
        //       .toFormat('dd/MM/yyyy HH:mm ')+'hrs';
        // }},
        // { key: "date_end", label: "Final",
        // formatter: (value, key, item) => {
        //   return DateTime
        //       .fromISO(item.date_end)
        //       .toFormat('dd/MM/yyyy HH:mm ')+'hrs';
        // }},
        { key: "actions", label: "Acciones" },
      ],
      Actions: [],
      items: [],
      loadNewUsers: [],
      codeText: "",
      codeActive: false,
      codeActiveClass: false,
      flags: {
        formUpload: false,
        fileUpload: false,
      },
      fileUpload: {},
      createSales: {},
      fileshow: "",
      date_start: "",
      date_end: "",
    };
  },
  methods: {
    formValidate() {
      let stateForm = false;

      if (this.createSales.name) {
        stateForm = true;
      }

      return stateForm;
    },
    showUser(uuid) {
      this.createSales = this.items.find((item) => {
        return item.uuid == uuid;
      });

      this.$bvModal.show("modal-show-admin");
    },
    editUser(uuid) {
      this.createSales = this.items.find((item) => {
        return item.uuid == uuid;
      });

      this.$bvModal.show("modal-edit-sales-admin");
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteUser(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid;
      this.$bvModal.show("modal-delete-confirmation");
    },
    deleteUser(uuid) {
      let items = this.items;
      Publicity.delete(uuid)
        .then((response) => {
          const index = items.findIndex((item) => item.uuid === uuid);
          if (~index) {
            items.splice(index, 1);
          }
          sweet.ToastMsg("", "success", "Categoria eliminado");
        })
        .catch((error) => {});
    },
    async create_sale() {
      if (this.formValidate()) {
        await Publicity.create({
          name: this.createSales.name,
        })
          .then((response) => {
            this.list();
            sweet.ToastMsg("", "success", "Categoria creado");
            this.$bvModal.hide("modal-add-sales-admin");
          })
          .catch((error) => {
            sweet.ToastMsg("", "success", "Categoria no creado");
          });
      } else {
        sweet.ToastMsg("", "error", "Campos no llenados");
      }
    },
    async submitFileEdit() {
      await Publicity.update(this.createSales.uuid, {
        name: this.createSales.name,
      })
        .then((response) => {
          this.$bvModal.hide("modal-edit-sales-admin");
          sweet.ToastMsg("", "success", "Categoria editado");
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "Categoria no editado");
        });
    },
    uploadFile(event) {
      this.fileUpload.file = event.target.files[0];
    },
    async list() {
      await Publicity.list({
        params: {
          limit: 5,
          page: 0,
        },
      })
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {});
    },
  },
  beforeMount() {},
  mounted() {
    this.list();
  },
};
</script>
